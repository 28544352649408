import { search, select, place, speed, instagram, facebook, whatsapp, tiktok, pinterest, threads } from '../assets';

export const navLinks = [
    {
        id: "tiles",
        title: "Tiles",
        links: [
            {
                name: "Ceramic Tiles",
                route: "/products/tiles?type=Ceramic Tiles",
            },
            {
                name: "Porcelain Tiles",
                route: "/products/tiles?type=Porcelain Tiles",
            },
            {
                name: "Floor Tiles",
                route: "/products/tiles?type=Floor Tiles",
            },
            {
                name: "Wall Tiles",
                route: "/products/tiles?type=Wall Tiles",
            },
            {
                name: "Backsplash Tiles",
                route: "/products/tiles?type=Backsplash Tiles",
            },
            {
                name: "Bathroom Tiles",
                route: "/products/tiles?type=Bathroom Tiles",
            },
            {
                name: "Kitchen Tiles",
                route: "/products/tiles?type=Kitchen Tiles",
            },
            {
                name: "Indoor Tiles",
                route: "/products/tiles?type=Indoor Tiles",
            },
            {
                name: "Outdoor Tiles",
                route: "/products/tiles?type=Outdoor Tiles",
            },
            {
                name: "Pool Tiles",
                route: "/products/tiles?type=Pool Tiles",
            },
            {
                name: "Commercial Tiles",
                route: "/products/tiles?type=Commercial Tiles",
            },
            {
                name: "Residential Tiles",
                route: "/products/tiles?type=Residential Tiles",
            },
            {
                name: "Brick Tiles",
                route: "/products/tiles?type=Brick Tiles",
            },
            {
                name: "All and Others (Tiles)",
                route: "/products/tiles?type=Other Tiles",
            },
        ],
    },
    {
        id: "marble",
        title: "Marble",
        links: [
            {
                name: "Carrara Marble",
                route: "/products/marble?type=Carrara Marble",
            },
            {
                name: "Calacatta Marble",
                route: "/products/marble?type=Calacatta Marble",
            },
            {
                name: "Statuario Marble",
                route: "/products/marble?type=Statuario Marble",
            },
            {
                name: "Crema Marfill Marble",
                route: "/products/marble?type=Crema Marfill Marble",
            },
            {
                name: "Emperador Marble",
                route: "/products/marble?type=Emperador Marble",
            },
            {
                name: "Nero Marquina Marble",
                route: "/products/marble?type=Nero Marquina Marble",
            },
            {
                name: "Travertine Marble",
                route: "/products/marble?type=Travertine Marble",
            },
            {
                name: "Onyx Marble",
                route: "/products/marble?type=Onyx Marble",
            },
            {
                name: "Marble Slabs",
                route: "/products/marble?type=Marble Slabs",
            },
            {
                name: "Marble Tiles",
                route: "/products/marble?type=Marble Tiles",
            },
            {
                name: "All and Others (Marble)",
                route: "/products/marble?type=Other Marble",
            },
        ],
    },
    {
        id: "granite",
        title: "Granite",
        links: [
            {
                name: "Absolute Black Granite",
                route: "/products/granite?type=Absolute Black Granite",
            },
            {
                name: "Tan Brown Granite",
                route: "/products/granite?type=Tan Brown Granite",
            },
            {
                name: "Giallo Ornamental Granite",
                route: "/products/granite?type=Giallo Ornamental Granite",
            },
            {
                name: "Santa Cecilia Granite",
                route: "/products/granite?type=Santa Cecilia Granite",
            },
            {
                name: "Ubatuba Granite",
                route: "/products/granite?type=Ubatuba Granite",
            },
            {
                name: "Kashmir White Granite",
                route: "/products/granite?type=Kashmir White Granite",
            },
            {
                name: "Baltic Brown Granite",
                route: "/products/granite?type=Baltic Brown Granite",
            },
            {
                name: "Black Galaxy Granite",
                route: "/products/granite?type=Black Galaxy Granite",
            },
            {
                name: "Blue Pearl Granite",
                route: "/products/granite?type=Blue Pearl Granite",
            },
            {
                name: "Tiger Skin Granite",
                route: "/products/granite?type=Tiger Skin Granite",
            },
            {
                name: "Granite Slabs",
                route: "/products/granite?type=Granite Slabs",
            },
            {
                name: "All and Others (Granites)",
                route: "/products/granite?type=Other Granite",
            },
        ],
    },
    {
        id: "sanitary",
        title: "Sanitary Wares",
        links: [
            {
                name: "Toilets",
                route: "/products/sanitary-wares?type=Toilets",
            },
            {
                name: "Basins/Sinks",
                route: "/products/sanitary-wares?type=Basins/Sinks",
            },
            {
                name: "Showers",
                route: "/products/sanitary-wares?type=Showers",
            },
            {
                name: "Bidets",
                route: "/products/sanitary-wares?type=Bidets",
            },
            {
                name: "Urinals",
                route: "/products/sanitary-wares?type=Urinals",
            },
            {
                name: "Faucets and Taps",
                route: "/products/sanitary-wares?type=Faucets and Taps",
            },
            {
                name: "Accessories",
                route: "/products/sanitary-wares?type=Accessories",
            },
            {
                name: "Drainage and Plumbing Fittings",
                route: "/products/sanitary-wares?type=Drainage and Plumbing Fittings",
            },
            {
                name: "Water Filtration/Purification Systems",
                route: "/products/sanitary-wares?type=Water Filtration/Purification Systems",
            },
        ],
    },
    {
        id: "floorandwall",
        title: "Floor and Wall Materials",
        links: [
            {
                name: "Hardwood",
                route: "/products/floor-and-wall-materials?type=Hardwood",
            },
            {
                name: "Laminate",
                route: "/products/floor-and-wall-materials?type=Laminate",
            },
            {
                name: "Vinyl",
                route: "/products/floor-and-wall-materials?type=Vinyl",
            },
            {
                name: "Carpet",
                route: "/products/floor-and-wall-materials?type=Carpet",
            },
            {
                name: "Urinals",
                route: "/products/floor-and-wall-materials?type=Urinals",
            },
            {
                name: "Paint",
                route: "/products/floor-and-wall-materials?type=Paint",
            },
            {
                name: "Wallpaper",
                route: "/products/floor-and-wall-materials?type=Wallpaper",
            },
            {
                name: "Wood Panelling",
                route: "/products/floor-and-wall-materials?type=Wood Panelling",
            },
            {
                name: "Wainscoting",
                route: "/products/floor-and-wall-materials?type=Wainscoting",
            },
        ],
    },
    {
        id: "more",
        title: "More Products",
        links: [
            {
                name: "Marble Slabs",
                route: "/products/marble-slab",
            },
            {
                name: "Granite Slabs",
                route: "/products/granite-slab",
            },
            {
                name: "Doors",
                route: "/products/doors",
            },
            {
                name: "Other Products",
                route: "/products/other-products",
            },
            {
                name: "Installation Services",
                route: "/products/installation",
            },
            {
                name: "Consultation",
                route: "/products/consultation",
            },
        ],
    },
    {
        id: "company",
        title: "Company",
        links: [
            {
                name: "About Us",
                route: "/about",
            },
            {
                name: "Contact Us",
                route: "/contact",
            },
            {
                name: "Careers",
                route: "/careers",
            },
            {
                name: "Terms of Usage",
                route: "/terms",
            },
            {
                name: "Privacy Policy",
                route: "/privacypolicy",
            },
        ],
    },
];

export const searchLinks = [
    {
        id: "tiles",
        title: "Tiles",
        links: [
            {
                name: "Ceramic Tiles",
                route: "",
            },
            {
                name: "Porcelain Tiles",
                route: "",
            },
            {
                name: "Floor Tiles",
                route: "",
            },
            {
                name: "Wall Tiles",
                route: "",
            },
            {
                name: "Backsplash Tiles",
                route: "",
            },
            {
                name: "Bathroom Tiles",
                route: "",
            },
            {
                name: "Kitchen Tiles",
                route: "",
            },
            {
                name: "Indoor Tiles",
                route: "",
            },
            {
                name: "Outdoor Tiles",
                route: "",
            },
            {
                name: "Pool Tiles",
                route: "",
            },
            {
                name: "Commercial Tiles",
                route: "",
            },
            {
                name: "Residential Tiles",
                route: "",
            },
            {
                name: "Brick Tiles",
                route: "",
            },
            {
                name: "All and Other (Tiles)",
                route: "",
            },
        ],
    },
    {
        id: "marble",
        title: "Marble",
        links: [
            {
                name: "Carrara Marble",
                route: "",
            },
            {
                name: "Calacatta Marble",
                route: "",
            },
            {
                name: "Statuario Marble",
                route: "",
            },
            {
                name: "Crema Marfill Marble",
                route: "",
            },
            {
                name: "Emperador Marble",
                route: "",
            },
            {
                name: "Nero Marquina Marble",
                route: "",
            },
            {
                name: "Travertine Marble",
                route: "",
            },
            {
                name: "Onyx Marble",
                route: "",
            },
            {
                name: "Marble Slabs",
                route: "",
            },
            {
                name: "Marble Tiles",
                route: "",
            },
            {
                name: "All and Other (Marble)",
                route: "",
            },
        ],
    },
    {
        id: "granite",
        title: "Granite",
        links: [
            {
                name: "Absolute Black Granite",
                route: "",
            },
            {
                name: "Tan Brown Granite",
                route: "",
            },
            {
                name: "Giallo Ornamental Granite",
                route: "",
            },
            {
                name: "Santa Cecilia Granite",
                route: "",
            },
            {
                name: "Ubatuba Granite",
                route: "",
            },
            {
                name: "Kashmir White Granite",
                route: "",
            },
            {
                name: "Baltic Brown Granite",
                route: "",
            },
            {
                name: "Black Galaxy Granite",
                route: "",
            },
            {
                name: "Blue Pearl Granite",
                route: "",
            },
            {
                name: "Tiger Skin Granite",
                route: "",
            },
            {
                name: "Granite Slabs",
                route: "",
            },
            {
                name: "All and Other (Granite)",
                route: "",
            },
        ],
    },
    {
        id: "marble-slab",
        title: "Marble Slab",
        links: [
            {
                name: "Carrara Marble Slabs",
                route: "",
            },
            {
                name: "Calacatta Marble Slabs",
                route: "",
            },
            {
                name: "Statuario Marble Slabs",
                route: "",
            },
            {
                name: "Nero Marquina Marble Slabs",
                route: "",
            },
            {
                name: "Crema Marfil Marble Slabs",
                route: "",
            },
            {
                name: "Emperador Marble Slabs",
                route: "",
            },
            {
                name: "Travertine Marble Slabs",
                route: "",
            },
            {
                name: "Onyx Marble Slabs",
                route: "",
            },
        ],
    },
    {
        id: "granite-slab",
        title: "Granite Slab",
        links: [
            {
                name: "Absolute Black Granite Slabs",
                route: "",
            },
            {
                name: "Tan Brown Granite Slabs",
                route: "",
            },
            {
                name: "Giallo Ornamental Granite Slabs",
                route: "",
            },
            {
                name: "Santa Cecilia Granite Slabs",
                route: "",
            },
            {
                name: "Blue Pearl Granite Slabs",
                route: "",
            },
            {
                name: "Black Galaxy Granite Slabs",
                route: "",
            },
            {
                name: "Kashmir White Granite Slabs",
                route: "",
            },
            {
                name: "Baltic Brown Granite Slabs",
                route: "",
            },
            {
                name: "Ubatuba Granite Slabs",
                route: "",
            },
        ],
    },
    {
        id: "sanitary-wares",
        title: "Sanitary Wares",
        links: [
            {
                name: "Toilets",
                route: "",
            },
            {
                name: "Basins/Sinks",
                route: "",
            },
            {
                name: "Showers",
                route: "",
            },
            {
                name: "Bidets",
                route: "",
            },
            {
                name: "Urinals",
                route: "",
            },
            {
                name: "Faucets and Taps",
                route: "",
            },
            {
                name: "Accessories",
                route: "",
            },
            {
                name: "Drainage and Plumbing Fittings",
                route: "",
            },
            {
                name: "Water Filtration/Purification Systems",
                route: "",
            },
        ],
    },
    {
        id: "floor-and-wall-materials",
        title: "Floor and Wall Materials",
        links: [
            {
                name: "Hardwood",
                route: "",
            },
            {
                name: "Laminate",
                route: "",
            },
            {
                name: "Vinyl",
                route: "",
            },
            {
                name: "Carpet",
                route: "",
            },
            {
                name: "Urinals",
                route: "",
            },
            {
                name: "Paint",
                route: "",
            },
            {
                name: "Wallpaper",
                route: "",
            },
            {
                name: "Wood Panelling",
                route: "",
            },
            {
                name: "Wainscoting",
                route: "",
            },
        ],
    },
    {
        id: "more",
        title: "More Products",
        links: [
            {
                name: "Doors",
                route: "",
            },
            {
                name: "Installation Services",
                route: "",
            },
            {
                name: "Consultation",
                route: "",
            },
        ],
    },
];

export const steps = [
    {
      image: search,
      title: "Search for products",
      description: "Click on the button below to search for whatever product you're looking for or have in mind and you'll find exact/related products.",
    },
    {
        image: select,
        title: "Select a product",
        description: "After finding the type or style of tile/other item you're looking for, select to show more details about the product and make your order/inquiry.",
    },
    {
        image: place,
        title: "Place your order",
        description: "After selecting an available item, you can place your order via WhatsApp or Email and you'll be responded to swiftly and how to move forward.",
    },
    {
        image: speed,
        title: "We will be with you speedily!",
        description: "After making your order via WhatsApp or Email, you will be contacted by us immediately on how to take the next steps.",
    },
];

export const socialMedia = [
    {
        id: "facebook",
        image: facebook,
        link: "https://facebook.com/shoptiles.ng"
    },
    {
        id: "instagram",
        image: instagram,
        link: "https://instagram.com/shoptiles.ng"
    },
    {
        id: "pinterest",
        image: pinterest,
        link: "https://pinterest.com/shoptiles.ng"
    },
    {
        id: "whatsapp",
        image: whatsapp,
        link: "https://wa.me/2349169861311"
    },
    {
        id: "thread",
        image: threads,
        link: "https://threads.net/shoptiles.ng"
    },
    {
        id: "tiktok",
        image: tiktok,
        link: "https://tiktok.com/fedeltiles"
    },
  ];
  
  export const footerLinks = [
    {
      title: "Tiles",
        links: [
            {
                name: "Ceramic Tiles",
                route: "/products/tiles?type=Ceramic Tiles",
            },
            {
                name: "Porcelain Tiles",
                route: "/products/tiles?type=Porcelain Tiles",
            },
            {
                name: "Floor Tiles",
                route: "/products/tiles?type=Floor Tiles",
            },
            {
                name: "Wall Tiles",
                route: "/products/tiles?type=Wall Tiles",
            },
            {
                name: "Backsplash Tiles",
                route: "/products/tiles?type=Backsplash Tiles",
            },
            {
                name: "Bathroom Tiles",
                route: "/products/tiles?type=Bathroom Tiles",
            },
            {
                name: "Kitchen Tiles",
                route: "/products/tiles?type=Kitchen Tiles",
            },
            {
                name: "Indoor Tiles",
                route: "/products/tiles?type=Indoor Tiles",
            },
            {
                name: "Outdoor Tiles",
                route: "/products/tiles?type=Outdoor Tiles",
            },
            {
                name: "Pool Tiles",
                route: "/products/tiles?type=Pool Tiles",
            },
            {
                name: "Commercial Tiles",
                route: "/products/tiles?type=Commercial Tiles",
            },
            {
                name: "Residential Tiles",
                route: "/products/tiles?type=Residential Tiles",
            },
            {
                name: "Brick Tiles",
                route: "/products/tiles?type=Brick Tiles",
            },
            {
                name: "All and Others (Tiles)",
                route: "/products/tiles?type=Other Tiles",
            },
        ],
    },
    {
      title: "Marble",
        links: [
            {
                name: "Carrara Marble",
                route: "/products/marble?type=Carrara Marble",
            },
            {
                name: "Calacatta Marble",
                route: "/products/marble?type=Calacatta Marble",
            },
            {
                name: "Statuario Marble",
                route: "/products/marble?type=Statuario Marble",
            },
            {
                name: "Crema Marfill Marble",
                route: "/products/marble?type=Crema Marfill Marble",
            },
            {
                name: "Emperador Marble",
                route: "/products/marble?type=Emperador Marble",
            },
            {
                name: "Nero Marquina Marble",
                route: "/products/marble?type=Nero Marquina Marble",
            },
            {
                name: "Travertine Marble",
                route: "/products/marble?type=Travertine Marble",
            },
            {
                name: "Onyx Marble",
                route: "/products/marble?type=Onyx Marble",
            },
            {
                name: "Marble Slabs",
                route: "/products/marble?type=Marble Slabs",
            },
            {
                name: "Marble Tiles",
                route: "/products/marble?type=Marble Tiles",
            },
            {
                name: "All and Others (Marble)",
                route: "/products/marble?type=Other Marble",
            },
        ],
    },

    {
        title: "Granite",
            links: [
                {
                    name: "Absolute Black Granite",
                    route: "/products/granite?type=Absolute Black Granite",
                },
                {
                    name: "Tan Brown Granite",
                    route: "/products/granite?type=Tan Brown Granite",
                },
                {
                    name: "Giallo Ornamental Granite",
                    route: "/products/granite?type=Giallo Ornamental Granite",
                },
                {
                    name: "Santa Cecilia Granite",
                    route: "/products/granite?type=Santa Cecilia Granite",
                },
                {
                    name: "Ubatuba Granite",
                    route: "/products/granite?type=Ubatuba Granite",
                },
                {
                    name: "Kashmir White Granite",
                    route: "/products/granite?type=Kashmir White Granite",
                },
                {
                    name: "Baltic Brown Granite",
                    route: "/products/granite?type=Baltic Brown Granite",
                },
                {
                    name: "Black Galaxy Granite",
                    route: "/products/granite?type=Black Galaxy Granite",
                },
                {
                    name: "Blue Pearl Granite",
                    route: "/products/granite?type=Blue Pearl Granite",
                },
                {
                    name: "Tiger Skin Granite",
                    route: "/products/granite?type=Tiger Skin Granite",
                },
                {
                    name: "Granite Slabs",
                    route: "/products/granite?type=Granite Slabs",
                },
                {
                    name: "All and Others (Granites)",
                    route: "/products/granite?type=Other Granite",
                },
            ],
        },

      {
        title: "Sanitary Wares",
            links: [
                {
                    name: "Toilets",
                    route: "/products/sanitary-wares?type=Toilets",
                },
                {
                    name: "Basins/Sinks",
                    route: "/products/sanitary-wares?type=Basins/Sinks",
                },
                {
                    name: "Showers",
                    route: "/products/sanitary-wares?type=Showers",
                },
                {
                    name: "Bidets",
                    route: "/products/sanitary-wares?type=Bidets",
                },
                {
                    name: "Urinals",
                    route: "/products/sanitary-wares?type=Urinals",
                },
                {
                    name: "Faucets and Taps",
                    route: "/products/sanitary-wares?type=Faucets and Taps",
                },
                {
                    name: "Accessories",
                    route: "/products/sanitary-wares?type=Accessories",
                },
                {
                    name: "Drainage and Plumbing Fittings",
                    route: "/products/sanitary-wares?type=Drainage and Plumbing Fittings",
                },
                {
                    name: "Water Filtration/Purification Systems",
                    route: "/products/sanitary-wares?type=Water Filtration/Purification Systems",
                },
            ],
        },
  
    {
      title: "More Products",
        links: [
            {
                name: "Marble Slabs",
                route: "/products/marble-slab",
            },
            {
                name: "Granite Slabs",
                route: "/products/granite-slab",
            },
            {
                name: "Doors",
                route: "/products/doors",
            },
            {
                name: "Other Products",
                route: "/products/other-products",
            },
            {
                name: "Installation Services",
                route: "/products/installation",
            },
            {
                name: "Consultation",
                route: "/products/consultation",
            },
        ],
    },
    {
        title: "Company",
        links: [
          {
            name: "About Us",
            route: "/about",
          },
          {
            name: "Contact Us",
            route: "/contact",
          },
          {
            name: "Careers",
            route: "/careers",
          },
          {
            name: "Terms of Usage",
            route: "/terms",
          },
          {
              name: "Privacy Policy",
              route: "/privacypolicy",
          },
        ],
      },
];